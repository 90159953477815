import axios from 'axios';
import apiUrl from '../globals/config';
export const ERRORMSG = 'Something went wrong.';
export const LOGOUT = 'Are you sure you want to log out?';
export const SIGNOUT = 'logout Successfully';
export const FAIL = 'Email or Password are not valid';

export const ONPAGE = 10;
export const ONPAGEFORPOST = 5;
export const STAGE = 2;
export const START = 1;
export const AGENT = 1;
export const ADMIN = 2;
export const DELAR = 3;
export const FINANCER = 4;
export const INSURANCE = 5;
export const CMT = 6;
export const filter = 9;
export const INDEX = 0;

export const DELETE = 'Are you sure you want to delete record?';
export const TITLE_DELETE = 'Delete';

export const DATANOTFOUND = 'No record found.';
export const CANNOTDELETE = 'Cannot do this action.';
export const MARKFAV = 'Favorite Marked.';
export const REMOVEFAV = 'Favorite Removed.';
export const DELMARKED = 'Delete Marked.';
export const DELTEMOVED = 'Delete Removed.';
export const DELETECONFERMATION = 'Confirm for delete.';
export const UPDATED = 'Updated Successfully';
export const DELETEMESSAGE = 'Are you sure want to Delete this ?';
export const APIBLOCK =
  'SUBSCRIPTION END PLEASE CONTACT WITH Toxsl Technologies Pvt. Ltd.';
export const PERPAGE = 10;
export const STATUS = ['active', 'inactive'];
export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
export const ADMINLOGIN = '/login';
export const ALLCONTENT = '/contentPagination?';
export const CONTENTTYPE = '/contentAccToType?';
export const NETWORTHDATA = '/netWorthFilter?';
export const FAMOUSLIST = '/profileFilter?';

export const DELETECONTENT = '/content-page/deleteContent/';
export const CONTENTDETAIL = '/content-page/getContent/';
export const ADDCONTENT = '/content-page/addContent';
export const ADDINDUSTRY = '/industry/';
export const INDUSTRYLIST = '/industryPagination?';

export const UPDATECONTENT = '/content-page/upadteContent/';
export const PROFESSIONN = '/professionPagination?';
export const PROFESSION = '/profession/';

export const UPDATEPROFILE = '/profile/';
export const ADMINDETAIL = '/user/';
export const GROUPLIST = '/group/';
export const GROUPLISTS = '/groups/';
export const GROUPLISTSS = '/groupUser/';

export const PAYMENT = '/paypalPayment/';

export const ADDDATABASEPAGE = '/database';
export const GETDATABASEPAGE = '/database?';
export const GETDBPAGEDETAIL = '/database/';
export const DELETEDATA = '/database/';
export const DELETECODE = '/code/';

export const GETPROFESSION = '/profession/';
export const GETINDUSTRIES = '/industry';
export const ACCSETTINGS = '/accountSetting';
export const GETFAMOUSPROFILE = '/profile/';
export const GETFAMOUSPROFILELIST = '/profileAccBtwf?';
export const GETDATABASE = '/database';
export const SIMILARPROFILEDETAIL = '/similarProfile/';
export const GETGROUP = '/group';
export const IndustryDetail = '/industry';
export const RECENTPROFILE = '/recentProfile';
export const RECENTCONTENT = '/content-page/recentContent';
export const ADDGROUP = '/group';
export const FAMOUSNEWS = '/contentAccToType?type=famousNewsPage&';
export const GETFAMOUSNEWS = '/content-page/getContent/';
export const SIMILARARICLE = '/contentAccToSimilar/';
export const SEARCHPROFILE = '/accToResult?';
export const UPDATEBTWFSCORE = '/updateBtwfScore/';
export const UPDATEBTWFGROUPSCORE = '/btwfScoreOfGroup/';

export const GROUPWITHTYPE = '/groupWithType/';
export const GROUP = '/group/';
export const INDUSRTYACCTOPROFESSION = '/industryAccToProfession/';
export const PROFILEACCTOPROFESSION = '/profileAccToProfession?_id=';
export const SEARCHFILTERR = '/searchProfile?';

export const UPDATEDBPAGE = '/database/';

export const DATABASESTATUS = '/databaseStatus/';
export const RECENTSPANISHPOST = '/contentAccToType?type=spanishPostPage';
export const GETFAMOUSPEOPLE = '/allProfile/';
export const MOVIESTARPOST = '/contentAccToType?type=movieStar';
export const RANDOMNUMBER = '/checkRandomNumber?userId=';
export const SUGGESTION = '/accToAge?keyword=';
export const DELETEGROUP = '/group/';
export const RECENTHOUSETOURPOST = '/contentAccToType?type=';

export const AUTHORISEDUSERLIST = '/users?';
export const ADDWRITER = '/register?';
export const DETAIL = '/user/';
export const DELETEUSER = '/user/';
export const UPDATEWRITER = '/user/';
export const USERREQUEST = '/userRequest?user_id=';
export const USERREQUESTT = '/userRequest?page=';

export const UPDATEREQUESTSTATUS = '/requestStatus/';
export const ALLPROFILE = '/allProfile/';
export const STATIC = '/staticPage';
export const CONTACT = '/contact';
export const STRENGTH = '/searchNumber';
export const GETSTATUS = '/enableDisable?';

export const ENABLEDISABLE = '/enableDisable/';

export const ADDFREEPROFILE = '/register/';
export const CONTACTUS = '/contact/';
export const PROMOCODE = '/verifyCode/';
export const CODE = '/code';
export const PROMOSTATUS = '/promoStatus/';
export const STATICC = '/staticPages?pageType=/';

export const Data = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
